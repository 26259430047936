// 2. This code loads the IFrame Player API code asynchronously.


        // 3. This function creates an <iframe> (and YouTube player)
        //    after the API code downloads.
        var player;
        var playtime;
        var playlist = ['9MNkfe0-wYU'];
        var currentVid = 0;
        function onYouTubeIframeAPIReady() {
          player = new YT.Player('youtube-player-div', {
          playerVars: {  'autoplay': 1, 'controls': 1, 'rel' : 0, 'showinfo': 0,'mute':1},
            height: '510',
            width: '1200',
            //videoId: 'sjHksKWNBpk',cMw5eJSBBd4
          videoId: playlist[currentVid],
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
              }
          });
        }

        // 4. The API will call this function when the video player is ready.
        function onPlayerReady(event) {
          event.target.mute();
          event.target.playVideo();
        }

        function onPlayerStateChange(event) {
          if(currentVid == (playlist.length - 1)){
            currentVid = 0;
          }else{
            currentVid += 1;
          }
          if (event.data == YT.PlayerState.ENDED) {
              player.loadVideoById(playlist[currentVid]);
          }
        }

        function stopVideo() {
          player.stopVideo();
        }
 
